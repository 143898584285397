<template>
  <div class="_bg-default _80w _100vh pa-3">
    <notifications group="errors" class="mt-2" position="top center" />
    <v-card class="radius-card pa-3 _100top">
      <div class="mb-3">
        <v-btn
          color="green"
          fab
          outlined
          small
          to="/psikolog/vacancies"
          content="back"
          v-tippy="{
            arrow: true,
            arrowType: 'round',
            animation: 'fade',
            theme: 'light',
            maxWidth: 100
          }"
          ><v-icon>mdi-reply</v-icon></v-btn
        >
      </div>
      <!-- LOGO -->
      <v-row>
        <v-col cols="12" md="4">
          <div class="upload-btn-wrapper">
            <div class="temp-cover d-flex align-center justify-center">
              <div v-if="!dp">
                <v-icon class="mr-3" size="30">mdi-camera-plus</v-icon> Upload
                Logo Company
                <input type="file" name="myfile" @change="getPic($event)" />
              </div>
              <div v-if="dp">
                <img
                  :src="dp"
                  width="100%"
                  height="200px"
                  class="img-fit"
                  alt="display picture"
                />
              </div>
            </div>
          </div>
          <v-btn v-if="dp" outlined color="green" rounded>
            <div class="upload-btn-wrapper add-width">
              <div class="pa-1">
                <v-icon class="mr-1"> mdi-pencil-box </v-icon>
                Change Picture
                <input type="file" @change="getPic($event)" name="myfile" />
              </div>
            </div>
          </v-btn>
        </v-col>

        <v-col cols="12" md="8">
          <v-text-field
            label="Company"
            v-model="company"
            required
            solo
          ></v-text-field>
          <v-text-field
            label="Job Title"
            v-model="title"
            required
            solo
          ></v-text-field>
          <vue-editor
            placeholder="Descriptions & Requirements"
            :editorToolbar="customToolbar"
            class="mb-3"
            v-model="descriptions"
          ></vue-editor>
          <!-- LOCATION -->
          <v-row>
            <v-col cols="12" md="6" v-if="listProvince">
              <v-select
                :options="listProvince.data"
                :reduce="item => item.id"
                v-model="selectedProvince"
                @search="query => (searchProvince = query)"
                label="nama_provinsi"
                placeholder="Province"
                class="white"
                @input="getCity(selectedProvince)"
              ></v-select>
              <!-- <small
                v-if="!this.$v.selectedProvince1.required"
                class="red--text"
                >{{ provErr[0] }}</small
              > -->
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                v-if="city"
                :options="city.data"
                :reduce="item => item.id"
                v-model="selectedCity"
                @search="query => (searchCity = query)"
                label="nama_kota"
                placeholder="City"
                class="white"
              ></v-select>
              <v-select v-else placeholder="City" class="white">
                <span slot="no-options" @click="$refs.select.open = false">
                  pilih provinsi terlebih dahulu
                </span>
              </v-select>
              <!-- <small v-if="!this.$v.selectedCity1.required" class="red--text">{{
                locErr[0]
              }}</small> -->
            </v-col>
          </v-row>
          <!-- CATEGORY AND EXPIRED -->
          <h4 class="mb-3">Category:</h4>
          <v-row>
            <v-col cols="12" md="6" v-if="fields">
              <v-select
                :options="fields.data"
                :reduce="item => item.nama"
                v-model="selectedField"
                @search="query => (searchField = query)"
                label="nama"
                placeholder="Choose Category"
                class="white"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-menu
                v-model="calendar2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="end_evt"
                    label="Expired Date"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    hide-details
                    dense
                    outlined
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="end_evt"
                  @input="calendar2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <footer class="_full-w d-flex justify-end mt-3">
        <v-btn rounded outlined color="green" @click="validate" v-if="!loading"
          ><v-icon class="mr-2">mdi-comment-check</v-icon>Save</v-btn
        >
        <v-btn rounded outlined color="green" v-if="loading"
          ><v-progress-circular
            indeterminate
            size="25"
            class="mr-2"
          ></v-progress-circular
          >Loading</v-btn
        >
      </footer>
    </v-card>
    <div class="pa-3">
      <ImageEmpty
        v-bind:dialogEmpty="dialogEmpty"
        @close="close"
        @next="next"
      />
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { VueEditor } from "vue2-editor";
import { mapState } from "vuex";
import ImageEmpty from "../_base/Modal/imageEmpty.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "createVacan",
  components: {
    VueEditor,
    ImageEmpty,
    vSelect
  },

  computed: {
    ...mapState({
      fields: state => state.fields.fields,
      listProvince: state => state.area.publicProvinces,
      city: state => state.area.filteredCity
    })
  },

  data() {
    return {
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        [{ color: [] }]
      ],
      pic: null,
      dp: null,
      title: "",
      descriptions: "",
      bidang: "Psikologi Klinis",
      dialogEmpty: false,
      loading: false,
      selectedField: "",
      company: "",
      searchProvince: "",
      searchCity: "",
      selectedProvince: null,
      selectedCity: null,
      searchField: null,
      start_evt: null,
      end_evt: null,
      calendar1: false,
      calendar2: false
    };
  },
  mounted() {
    this.getFields();
    this.$store.dispatch("area/publicProvince");
  },
  methods: {
    getCity(id) {
      this.$store.dispatch("area/filterCity", id);
    },
    getFields() {
      this.$store.dispatch("fields/listFields");
    },
    validate() {
      if (this.pic == null) {
        this.dialogEmpty = true;
      } else {
        this.postItem();
      }
    },
    next() {
      this.postItem();
      this.close();
    },
    close() {
      this.dialogEmpty = false;
    },

    getPic(e) {
      const file = e.target.files[0];
      const fr = new FileReader();
      fr.onload = f => {
        this.dp = f.target.result;
      };
      fr.readAsDataURL(file);
      this.pic = file;
    },

    postItem() {
      this.loading = true;
      let data = new FormData();
      data.append("perusahaan", this.company);
      data.append("judul", this.title);
      data.append("nama_kategori", this.selectedField);
      data.append("kota_id", this.selectedCity);
      data.append("deskripsi", this.descriptions);
      data.append("start_date", this.$date().format("YYYY-MM-DD"));
      data.append("end_date", this.end_evt);
      data.append("objLogo", this.pic);
      this.$store
        .dispatch("vacancies/createVacancies", data)
        .then(data => {
          console.log(data.status);
          this.loading = false;
          if (data.status == "failed") {
            let err = data.data.errors;
            console.log(err);
            for (let i in err) {
              this.alert("error", `${err[i][0]}`);
            }
          } else {
            Swal.fire({
              icon: "success",
              title: data.message,
              showConfirmButton: false,
              position: "top",
              toast: true,
              timer: 3000
            });
            this.dp = null;
            this.company = "";
            this.selectedField = "";
            this.title = "";
            this.descriptions = "";
            this.company = "";
            this.start_evt = null;
            this.end_evt = null;
          }
        })
        .catch(err => {
          console.log(err);
          this.loading = false;
        });
    },

    alert(type, title) {
      this.$notify({
        group: "errors",
        type: type,
        title: title
      });
    }
  }
};
</script>

<style scoped>
.temp-cover {
  width: 100%;
  height: 200px;
  border: 1px #000 dotted;
}
</style>
